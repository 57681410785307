<script>
import appConfig from "@/app.config";

import {mapState} from "vuex";

import {
  required,
  email
} from "vuelidate/lib/validators";

export default {
  components: {

  },
  data() {
    return {
      email: ""
    };
  },
  validations: {
    email: { required, email },
    form: ['email']
  },
  created() {

  },
  methods: {
    validate() {
      this.$v.form.$touch();
      let isValid = !this.$v.form.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    }
  }
};
</script>
<template>
  <div class="form-group">
    <label>
      {{ $t('account.email.change.prompt') }}
    </label>
    <div>
      <input
          v-model="email"
          type="email"
          name="email"
          class="form-control"
          :class="{ 'is-invalid': $v.email.$error }"
          :placeholder="$t('account.email.change.prompt')"
      />
      <div v-if="$v.email.$error" class="invalid-feedback">
        <span v-if="!$v.email.required">
          {{ $t('account.email.change.error.required') }}
        </span>
        <span v-if="!$v.email.email">
          {{ $t('account.email.change.error.email') }}
        </span>
      </div>
    </div>
  </div>
</template>